import React, { Suspense, useEffect, useState } from "react";

// routing
import { Switch, BrowserRouter, Route, useHistory } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
//types
import { SET_ADMIN, UNSET_ADMIN } from "./store/admin/types";

import { IdleTimeoutManager } from "idle-timer-manager";

//Components
import Login from "./pages/LoginPage";
import UnlockScreenPage from "./pages/UnlockScreenPage";
import Page404 from "./pages/Page404";
import Admin from "./pages/Admin";
import AuthRouter from "./util/AuthRouter";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import axios from "axios";
import Registration from "./pages/Registration";
import UpdateCode from "./pages/UpdateCode";
import Spinner from "./pages/Spinner";

function App() {
  const dispatch = useDispatch();
  const isAuth = localStorage.getItem("isAuth");
  const [ipAddress, setIpAddress] = useState("");

  const token = localStorage.getItem("TOKEN");

  const key = localStorage.getItem("KEY");
  // const [login, setLogin] = useState(true);

  // useEffect(() => {
  //   axios
  //     .get("/login")
  //     .then((res) => {
  //       console.log("res.data", res.data);
  //       setLogin(res.data.login);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    if (token && key) {
      if (isAuth) {
        dispatch({ type: SET_ADMIN, payload: token });
      } 
    }
  }, [dispatch, isAuth, token, key]);

  useEffect(() => {
    fetch("https://ipapi.co/json/", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {

        setIpAddress(data?.network);
        localStorage.setItem("ipAddress", data?.network);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  useEffect(() => {
    const manager = new IdleTimeoutManager({
      timeout: 1800, //30 min (in sec)
      onExpired: (time) => {
        dispatch({ type: UNSET_ADMIN });
        return (window.location.href = "/");
      },
    });

    return () => {
      manager.clear();
    };
  }, []);

  return (
    <div className="App">
      <Suspense fallback={""}>
        <BrowserRouter>
          <Switch>
            {/* <AuthRouter exact path="/" component={login ? Login : Registration} /> */}
            <AuthRouter exact path="/" component={Login} />
            {/* <AuthRouter exact path="/code" component={UpdateCode} /> */}
            <AuthRouter exact path="/login" component={Login} />
           
           
            {/* <AuthRouter path="/Registration" component={Registration} /> */}
            <AuthRouter exact path="/unlock" component={UnlockScreenPage} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route
              exact
              path="/changePassword/:id"
              component={ChangePassword}
            />
            {isAuth && <Route path="/admin" component={Admin} />}
           


            <Route component={Page404} />
          </Switch>
          <Spinner />
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
