import React, { useEffect, useState } from "react";

//react-redux
import { useSelector, useDispatch } from "react-redux";

//routing
import { Link } from "react-router-dom";

//dayjs
import dayjs from "dayjs";

import arraySort from "array-sort";
import {
  acceptHostReq,
  acceptHostRequest,
  getHostRequest,
} from "../../../store/hostRequest/action";
import Pagination from "../../../pages/Pagination";
import AcceptedRequest from "./AcceptedRequest";
import DeclineRequest from "./DeclineRequest";
import {
  OPEN_AGENCY_CODE_DIALOGUE,
  OPEN_BANK_DETAILS_DIALOGUE,
  OPEN_REASON_DIALOGUE,
} from "../../../store/hostRequest/type";
import ReasonDialogue from "./ReasonDialogue";
import AddAgencyCodeDialogue from "./AddAgencyCodeDialogue";

import BankDetailsDialogue from "./BankDetailsDialogue";
// import ReasonDialogue from "./ReasonDialogue";
// import AddAgencyCodeDialogue from "./AddAgencyCodeDialogue";

const PendingRequest = () => {
  const dispatch = useDispatch();
  const { request, total } = useSelector((state) => state.hostRequest);
  
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  console.log("data", data);

  useEffect(() => {
    dispatch(getHostRequest(activePage, rowsPerPage, 1));
  }, [activePage, rowsPerPage, 1]);

  useEffect(() => {
    setData(request);
  }, [request]);

  //   pagination

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const handleAcceptDecline = (data, type) => {
    
    console.log("data", data);
    
    if (
      (data?.agencyCode === "" || data?.agencyCode == null) &&
      type === "accept"
    )
    {
      dispatch({
        type: OPEN_AGENCY_CODE_DIALOGUE,
        payload: { data: data, type: type },
      });
    } else {
      dispatch({
        type: OPEN_BANK_DETAILS_DIALOGUE,
        payload: { data: data, type: type },
      });
    }
  };
  const handleDecline = (id, type) => {
    dispatch({ type: OPEN_REASON_DIALOGUE, payload: { id: id, type: type } });
  };

  return (
    <>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left"></div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right"></div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table class="table table-striped">
                <thead className="text-white">
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>User Name</th>

                    <th>Agency Code</th>

                    <th>CreatedAt</th>
                    <th>Accept</th>
                    <th>Decline</th>
                  </tr>
                </thead>
                <tbody className="t">
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.user?.image}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              className="mx-auto"
                            />
                          </td>
                          <td>{data?.user?.name}</td>
                          <td>{data?.agencyCode ? data?.agencyCode : "-"}</td>

                          <td>
                            {dayjs(data?.createdAt).format("DD MMM, YYYY")}
                          </td>
                          <td>
                            <button
                              class="btn btn-sm text-white success"
                              onClick={() =>
                                handleAcceptDecline(data, "accept")
                              }
                            >
                              <i class="fa fa-check"></i> Accept
                            </button>
                          </td>
                          <td>
                            <button
                              class="btn btn-sm text-white danger"
                              onClick={() =>
                                handleAcceptDecline(data, "decline")
                              }
                            >
                              <i class="fas fa-times"></i> Decline
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={total}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <ReasonDialogue />
      <AddAgencyCodeDialogue />
      <BankDetailsDialogue />
    </>
  );
};

export default PendingRequest;
