import React from "react";
import { useState } from "react";
import Commission from "./Commission";
import { Link } from "react-router-dom/cjs/react-router-dom";
import HostCommission from "./hostCommision/HostCommision";

const CommissionMain = () => {
  const [type, setType] = useState("HostCommission");
  return (
    <div>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Agency Commission</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                   Commission
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="my-2 mb-4">
            {/* <button
              type="button"
              className={`btn btn-sm ${
                type === "HostCommission" ? "btn-info" : "disabledBtn"
              }`}
              onClick={() => setType("HostCommission")}
            >
              <span className="">Host Commission</span>
            </button> */}
            {/* <button
              type="button"
              className={`btn btn-sm ${
                type === "AgencyCommission" ? "btn-danger" : "disabledBtn"
              } ms-3`}
              onClick={() => setType("AgencyCommission")}
            >
              <span className="">Agency Commission</span>
            </button> */}
          </div>
        </div>
      </div>

      {/* {type === "AgencyCommission" && ( */}
        <>
          <Commission />
        </>
      {/* )} */}
      {/* {type === "HostCommission" && (
        <>
          <HostCommission />
        </>
      )} */}
    </div>
  );
};

export default CommissionMain;
