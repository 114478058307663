export const GET_AVATAR_FRAME_GIF =  "GET_AVATAR_FRAME_GIF"  

export const OPEN_DIALOGUE_AVATAR_FRAME  = "OPEN_DIALOGUE_AVATAR_FRAME"

export const CLOSE_DIALOGUE_AVATAR_FRAME  = "CLOSE_DIALOGUE_AVATAR_FRAME"

export const CERATE_AVATAR_FRAME_GIF =  "CERATE_AVATAR_FRAME_GIF"  

export const UPDATE_AVATAR_FRAME_GIF =  "UPDATE_AVATAR_FRAME_GIF"  

export const DELETE_AVATAR_FRAME_GIF =  "DELETE_AVATAR_FRAME_GIF"  





