import { CLOSE_BROADCAST_DIALOG, CREATE_NEW_BROADCAST, DELETE_BROADCAST, GET_BROADCAST, OPEN_BROADCAST_DIALOG } from "./types";


const initialState = {
    broadCast: [],
    dialog: false,
    dialogData: null,
};

const broadCastReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BROADCAST:
            return {
                ...state,
                broadCast: action.payload,
            };
        case CREATE_NEW_BROADCAST:
            const data = [...state.broadCast];
            data.unshift(action.payload);
            return {
                ...state,
                broadCast: data,
            };

        case DELETE_BROADCAST:
            return {
                ...state,
                broadCast: state.broadCast.filter((broadCast) => broadCast._id !== action.payload),
            };
        case OPEN_BROADCAST_DIALOG:
            return {
                ...state,
                dialog: true,
                dialogData: action.payload || null,
            };
        case CLOSE_BROADCAST_DIALOG:
            return {
                ...state,
                dialog: false,
                dialogData: null,
            };

        default:
            return state;
    }
};

export default broadCastReducer;
