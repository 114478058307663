import axios from "axios";
import { Toast } from "../../util/Toast";

import { apiInstanceFetch } from "../../util/api";
import { CLOSE_BROADCAST_DIALOG, CREATE_NEW_BROADCAST, DELETE_BROADCAST, GET_BROADCAST } from "./types";

export const getBroadCast = () => (dispatch) => {
    apiInstanceFetch
        .get(`broadcastBanner`)
        .then((res) => {
            if (res.status) {
                dispatch({ type: GET_BROADCAST, payload: res.broadcastBanner });
            } else {
                Toast("error", res.message);
            }
        })
        .catch((error) => Toast("error", error.message));
};




export const createBroadCast = (data) => (dispatch) => {
    return axios
        .post(`broadcastBanner`, data)
        .then((res) => {
            if (res.data.status) {
                // Toast("success", "Banner created successfully!");
                dispatch({ type: CLOSE_BROADCAST_DIALOG });
                dispatch({ type: CREATE_NEW_BROADCAST, payload: res.data.banner });
            } else {
                Toast("error", res.data.message);
            }
            return res.data; // Return res.data to allow chaining
        })
        .catch((error) => {
            Toast("error", error.message);
            throw error; // Throw error to handle in .catch of handleSubmit if needed
        });
};

export const deleteBroadCast = (bannerId) => (dispatch) => {
    console.log("idddd", bannerId)
    axios
        .delete(`broadcastBanner?broadcastBannerId=${bannerId}`)
        .then((res) => {
            if (res.data.status) {
                dispatch({ type: DELETE_BROADCAST, payload: bannerId });
            } else {
                Toast("error", res.data.message);
            }
        })
        .catch((error) => console.log(error));
};
