export const GET_GIFT = "GET_GIFT";
export const CREATE_NEW_GIFT = "CREATE_NEW_GIFT";
export const CREATE_NEW_GIFT_SVGA = "CREATE_NEW_GIFT_SVGA";
export const EDIT_GIFT = "EDIT_GIFT";
export const DELETE_GIFT = "DELETE_GIFT";

export const OPEN_GIFT_DIALOG = "OPEN_GIFT_DIALOG";
export const CLOSE_GIFT_DIALOG = "CLOSE_GIFT_DIALOG";

export const OPEN_SVGA_DIALOG = "OPEN_SVGA_DIALOG";
export const CLOSE_SVGA_DIALOG = "CLOSE_SVGA_DIALOG";
