import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import $ from "jquery";
import noImage from "../../assets/images/noImage.png";
import { baseURL } from "../../util/Config";
import { createBroadCast, getBroadCast } from "../../store/broadCast/action";
import { CLOSE_BROADCAST_DIALOG } from "../../store/broadCast/types";
import { toast } from "react-toastify";

const BroadCastBannerDialogue = (props) => {
    const dispatch = useDispatch();

    const { dialog: open, dialogData } = useSelector((state) => state.broadCast);
    const [imageData, setImageData] = useState(null);
    const [imagePath, setImagePath] = useState(null);


    const [errors, setError] = useState({
        link: "",
        image: "",
        banerType: "",
    });
    $(document).ready(function () {
        $("img").bind("error", function () {
            // Set the default image
            $(this).attr("src", noImage);
        });
    });
    useEffect(() => {
        if (dialogData) {
            setImagePath(baseURL + dialogData.image);
        }
    }, [dialogData]);

    useEffect(() => {
        window.onbeforeunload = closePopup();
    }, []);

    const HandleInputImage = (e) => {
        setError({ image: "" });
        if (e.target.files[0]) {
            setImageData(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImagePath(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const closePopup = () => {
        dispatch({ type: CLOSE_BROADCAST_DIALOG });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = {};
        if ((!imageData)) {
            errors.image = "image is Required!";
        }
        if (Object.keys(errors).length > 0) {
            return setError(errors);
        }

        const formData = new FormData();
        formData.append("image", imageData);


        props.createBroadCast(formData)
            .then((res) => {
                console.log("ressssssssss", res)
                if (res?.status) {
                    toast.success("Broad Cast Banner Created Successfully");
                    dispatch({ type: CLOSE_BROADCAST_DIALOG });
                    dispatch(getBroadCast())
                } else {
                    toast.error(res?.message);
                }
            })

    };
    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="responsive-dialog-title"
                onClose={closePopup}
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="responsive-dialog-title">
                    <span className="text-danger font-weight-bold h4"> Broad Cast Banner</span>
                </DialogTitle>

                <IconButton
                    style={{
                        position: "absolute",
                        right: 0,
                    }}
                >
                    <Tooltip title="Close">
                        <Cancel className="text-danger" onClick={closePopup} />
                    </Tooltip>
                </IconButton>
                <DialogContent>
                    <div className="modal-body pt-1 px-1 pb-3">
                        <div className="d-flex flex-column">
                            <form>
                                {

                                    <div className="form-group mt-4">
                                        <label className="mb-2 text-gray">Broad Cast Image</label>
                                        <input
                                            type="file"
                                            className="form-control form-control-sm"
                                            accept="image/jpg ,image/jpeg ,image/png"
                                            required=""
                                            onChange={HandleInputImage}
                                        />
                                        {errors.image && (
                                            <div className="ml-2 mt-1">
                                                {errors.image && (
                                                    <div className="pl-1 text__left">
                                                        <span className="text-red">{errors.image}</span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {imagePath && (
                                            <>
                                                <img
                                                    height="70px"
                                                    width="70px"
                                                    alt="app"
                                                    src={imagePath}
                                                    style={{
                                                        boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                        // border: "2px solid #fff",
                                                        borderRadius: 10,
                                                        marginTop: 10,
                                                        float: "left",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>

                                }

                                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                                    <button
                                        type="button"
                                        className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                                        onClick={closePopup}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-round float__right btn-danger"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default connect(null, { createBroadCast })(BroadCastBannerDialogue);
